import React from 'react';

function Pandas() {
  return (
    <div className='flex items-center justify-center'>
        <svg viewBox="0 0 128 128" width="70" height="70" preserveAspectRatio="xMidYMin">
            <path d="M48.697 15.176h12.25v25.437h-12.25zm0 52.251h12.25v25.436h-12.25z" color="#ffffff" fill="#ffffff"></path><path d="M48.697 48.037h12.25v12.001h-12.25z" color="#ffffff" fill="#ffffff"></path><path d="M29.017 36.087h12.25v84.552h-12.25zM67.97 88.414h12.25v25.436H67.97zm0-52.297h12.25v25.437H67.97z" color="#ffffff" fill="#ffffff"></path><path d="M67.97 68.983h12.25v12.001H67.97z" color="#ffffff" fill="#ffffff"></path><path d="M87.238 8.55h12.25v84.552h-12.25z" color="#ffffff" fill="#ffffff"></path>
        </svg>  
    </div>
  );
}

export default Pandas;