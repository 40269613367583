import './index.css';
import React, { useRef, useState } from 'react'
import { useFrame } from '@react-three/fiber'

function Shape(props) {
  const meshRef = useRef()
  const [hovered, setHover] = useState(false)
  const [scale, setScale] = useState(1)

  useFrame((state, delta) => {
    // Adjust the rotation as needed
    meshRef.current.rotation.x += 0.2 * delta;
    meshRef.current.rotation.y += -0.2 * delta;
    meshRef.current.rotation.z += 0.1 * delta;

    // Smoothly interpolate the scale on hover
    const targetScale = hovered ? 1.05 : 1
    setScale((prevScale) => prevScale + (targetScale - prevScale) * 0.05)
  });

  return (
    <mesh
      {...props}
      ref={meshRef}
      scale={[scale, scale, scale]}
      onPointerOver={() => setHover(true)}
      onPointerOut={() => setHover(false)}
    >
      <torusKnotGeometry args={[3, 0.7, 40, 6]}/> 
      <meshStandardMaterial wireframe color={'magenta'} />
    </mesh>
  )
}

export default Shape;
