import React from "react";
import ReactDOM from "react-dom/client";

import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { Helmet } from "react-helmet";

const root = ReactDOM.createRoot(document.getElementById("root"));
const structuredData = {
  "@context": "https://anirudlappathi.com",
  "@type": "WebSite",
  url: "https://www.anirudlappathi.com/",
  name: "Aniruddha Lappathi from the University of Illinois at Urbana-Champaign's portfolio website",
  potentialAction: [
    {
      "@type": "ViewAction",
      target: "https://www.linkedin.com/in/{profile_url}/",
      "query-input": "required name=profile_url",
    },
    {
      "@type": "ViewAction",
      target: "https://leetcode.com/u/{profile_url}/",
      "query-input": "required name=profile_url",
    },
    {
      "@type": "ViewAction",
      target: "https://github.com/{profile_url}",
      "query-input": "required name=profile_url",
    },
    {
      "@type": "ViewAction",
      target: "https://www.anirudlappathi.com/files/{document_id}.pdf",
      "query-input": "required name=document_id",
    },
  ],
};
root.render(
  <>
    <Helmet>
      <title>anirudlappathi.com</title>
      <html lang="en" />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" href="./assets/favicon_io/favicon.ico" />
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
      <meta
        name="description"
        content="This is Aniruddha Lappathi's personal website which showcases his internships, projects, Github, Linkedin, and Leetcode. Also contains all of this other achievements like clubs and skills."
      />
      <meta
        name="keywords"
        content="React, TailwindCSS, JavaScript, Website, Portfolio, Computer Science, University of Illinois at Urbana-Champaign"
      />
      <meta property="og:title" content="Aniruddha Lappathi's website" />
      <meta
        property="og:description"
        content="This is Aniruddha Lappathi's personal website which showcases his internships, projects, Github, Linkedin, and Leetcode. Also contains all of this other achievements like clubs and skills."
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.anirudlappathi.com" />
      <meta
        property="og:image"
        content="https://www.anirudlappathi.com/files/resume_aniruddha_lappathi.pdf"
      />
      <meta
        name="twitter:card"
        content="Website of Anirud Lappathi's website"
      />
      <meta
        name="twitter:title"
        content="Anirud Lappathi Portfolio website front page"
      />
      <meta
        name="twitter:description"
        content="This is Aniruddha Lappathi's personal website which showcases his internships, projects, Github, Linkedin, and Leetcode. Also contains all of this other achievements like clubs and skills."
      />
      <meta
        name="twitter:image"
        content="https://www.anirudlappathi.com/files/resume_aniruddha_lappathi.pdf"
      />
    </Helmet>
    <App />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
