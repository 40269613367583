import DownloadImageBlack from "./assets/downloadImageBlack.svg";
import DownloadImageWhite from "./assets/downloadImageWhite.svg";
import { Link as RouterLink } from "react-router-dom";

function ResumeButton({ onWhite }) {
  return (
    <>
      {onWhite ? (
        <RouterLink
          to="/resume"
          draggable="false"
          className="flex items-center lg:text-lg text-xs font-jetbrains-regular border-solid border-black border-2 rounded-md lg:p-3 p-1
                hover:scale-110 transition-transform select-none">
          <span>Resume</span>
          <img
            className="ml-2 min-w-1 max-w-12"
            src={DownloadImageBlack}
            alt=""
          />
        </RouterLink>
      ) : (
        <RouterLink
          to="/resume"
          draggable="false"
          className="flex items-center font-jetbrains-regular border-solid border-white border-2 rounded-md p-3
      hover:scale-110 transition-transform select-none">
          <span className="text-white">Resume</span>
          <img
            className="ml-2 lg:block hidden"
            src={DownloadImageWhite}
            alt=""
          />
        </RouterLink>
      )}
    </>
  );
}

export default ResumeButton;
