import './index.css'

function AboutMe({ boxComponent }) {
    return (
        <div id='aboutMe' className='flex w-screen h-auto min-h-screen'>
            <div className='w-3/5 h-screen my-auto lg:w-1/2 lg:block hidden'>
                { boxComponent }
            </div>
            <div className='align-top lg:p-12 p-4 lg:w-2/5 w-max'>
                <div className='lg:text-7xl text-5xl pt-24 lg:text-left text-center font-jetbrains-bold'>
                    ABOUT ME
                </div>
                <div className='lg:pr-6 pb-12 mb-16 lg:text-left text-center text-lg lg:text-2xl font-jetbrains-regular'>
                    As a Computer Science + Crop Sciences major at the University of Illinois Urbana-Champaign, 
                    I want to fill the potential within the agritech sector and pursue the intersection between the two majors. 
                    I am interested in working on upcoming computer vision technologies to improve the efficiency of agriculture to enhance food security, lower environmental impact, and promote social equality.
                </div>
            </div>
        </div>
    );
}

export default AboutMe;