import "./index.css";
import BayerLogo from "../assets/bayerlogo.png";
import MarketMediumLogo from "../assets/marketmedium_logo.jpeg";
import VarianLogo from "../assets/varianlogo.png";

function ProjectCell({
  projectName,
  projectLink,
  projectLinkName,
  description,
  year,
  imageLink,
}) {
  let logo;
  switch (imageLink) {
    case "Bayer":
      logo = BayerLogo;
      break;
    case "MM":
      logo = MarketMediumLogo;
      break;
    case "Varian":
      logo = VarianLogo;
      break;
    default:
      logo = imageLink;
      break;
  }
  return (
    <a
      draggable="false"
      href={projectLink}
      target="_blank"
      rel="noreferrer"
      className="p-5 border-white rounded-md border-solid border-2 hover:scale-105 transition-transform max-h-1/2 min-w-1/5">
      <span className="block text-white text-center text-3xl font-jetbrains-bolder">
        {projectName}
      </span>
      <span className="block  text-white text-center text-sm font-jetbrains-regular">
        {projectLinkName}
      </span>
      <span className="block  text-white text-center text-xs font-jetbrains-regular">
        {year}
      </span>
      <img
        src={logo}
        alt={`${projectName} Logo`}
        className="block mx-auto my-4 w-24 h-24 object-cover"
      />
      <span
        className={`block text-white my-5 mx-5 text-lg font-jetbrains-regular ${
          imageLink === "Bayer" ? "text-center" : ""
        }`}>
        {description}
      </span>
    </a>
  );
}

function Internships() {
  const projectData = [
    {
      projectName: "MARKET MEDIUM",
      projectLink: "https://www.linkedin.com/company/marketmedium/",
      year: "Summer 2021, Summer 2022",
      projectLinkName: "Software Engineer Intern",
      description:
        "I used Java to create software that queries large construction documents using keyword search. Maximized accessibility by using AWS to host a front-end application for the engineers. Learned about the CI/CD and team development practices like Docker and proper documentation",
      imageLink: "MM",
    },
    {
      projectName: "VARIAN",
      projectLink: "https://www.varian.com/",
      year: "Summer 2024 - Present",
      projectLinkName: "Software Engineer Intern",
      description:
        "Developed an code review pipeline that automatically reviews pull requests for errors using linters and generative AI. Created a semantic testing keyword searcher to improve testcase creation for automation team. Developed a software that can manipulate large amounts of DICOM medical files for testing.",
      imageLink: "Varian",
    },
    {
      projectName: "BAYER",
      projectLink: "https://www.bayer.com/en/",
      year: "Fall 2024",
      projectLinkName: "Software Engineer Intern",
      description:
        "Developing a scalable infrastructure to improve internal document accessibility",
      imageLink: "Bayer",
    },
  ];

  return (
    <div id="projects" className="w-screen h-auto bg-black text-white p-10">
      <div className="pb-10">
        <div className="text-5xl text-center font-jetbrains-regular select-none">
          INTERNSHIPS
        </div>
        <div className="pb-10 text-md text-center font-jetbrains-regular select-none">
          throughout the years
        </div>
        <div className="grid lg:grid-cols-3 grid-cols-1 gap-8">
          {projectData.map((skill, index) => (
            <ProjectCell
              key={index}
              projectName={skill.projectName}
              projectLink={skill.projectLink}
              projectLinkName={skill.projectLinkName}
              description={skill.description}
              imageLink={skill.imageLink}
              year={skill.year}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Internships;
