import "./index.css";
import { Link as RouterLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import ResumeButton from "../ResumeButton";

function Header({ linkedLogo, hasHeaderButtons = true }) {
  return (
    <nav
      id="header"
      className="flex items-center justify-between lg:p-4 p-2 lg:pl-20">
      <div className="">
        <span className="lg:text-xl text-xs font-jetbrains-regular select-none ml-5">
          {linkedLogo ? (
            <RouterLink to="/" draggable="false">
              anirudlappathi.com
            </RouterLink>
          ) : (
            "anirudlappathi.com"
          )}
        </span>
      </div>
      {hasHeaderButtons ? (
        <ul className="flex items-center lg:text-lg text-xs/[10px] font-jetbrains-regular">
          <ScrollLink
            to="projects"
            smooth={true}
            duration={500}
            className="hover:cursor-pointer lg:mx-4 hidden lg:block mx-1 lg:p-3 p-1 hover:scale-110 transition-transform lg:border-none border-solid border-black border-2 rounded-md select-none">
            Projects
          </ScrollLink>
          <ScrollLink
            to="aboutMe"
            smooth={true}
            duration={500}
            className="hover:cursor-pointer lg:mx-4 hidden lg:block mx-1 lg:p-3 p-1 hover:scale-110 transition-transform lg:border-none border-solid border-black border-2 rounded-md select-none">
            About Me
          </ScrollLink>
          <ScrollLink
            to="skills"
            smooth={true}
            duration={500}
            className="hover:cursor-pointer lg:mx-4 hidden lg:block mx-1 lg:p-3 p-1 hover:scale-110 transition-transform lg:border-none border-solid border-black border-2 rounded-md select-none">
            Skills
          </ScrollLink>
          <ScrollLink
            to="contactMe"
            smooth={true}
            duration={500}
            className="hover:cursor-pointer lg:mx-4 hidden lg:block mx-1 lg:p-3 p-1 hover:scale-110 transition-transform lg:border-none border-solid border-black border-2 rounded-md select-none">
            Contact Me
          </ScrollLink>
        </ul>
      ) : (
        ""
      )}
      <div className="mr-5">
        <ResumeButton onWhite={true} />
      </div>
    </nav>
  );
}

export default Header;
