import Research from "./Research";

function ClubCell({ clubName, clubLink, clubLinkName, description, year }) {
  return (
    <a
      draggable="false"
      href={clubLink}
      target="_blank"
      rel="noreferrer"
      className="p-5 border-black rounded-md border-solid border-2 hover:scale-105 transition-transform max-h-1/2 min-w-1/5">
      <span className="block text-black text-center text-3xl font-jetbrains-bolder">
        {clubName}
      </span>
      <span className="block  text-black text-center text-sm font-jetbrains-regular">
        {clubLinkName}
      </span>
      <span className="block  text-black text-center text-xs font-jetbrains-regular">
        {year}
      </span>
      <span className="block text-black my-5 mx-5 text-lg font-jetbrains-regular">
        {description}
      </span>
    </a>
  );
}

function Clubs() {
  const clubData = [
    {
      clubName: "ILLINOIS BUSINESS CONSULTING",
      clubLink: "https://www.instagram.com/illinoisbusinessconsulting/",
      year: "Fall 2023",
      clubLinkName: "Software Engineer",
      description:
        "I automated the recruiting process for consultant applications and interviews, enhancing gender and race equality by implementing an algorithm for statistical analysis using Pandas and NumPy, and integrating it with Flask for web usage. Additionally, I architected a dashboard with Python’s Dash and Plotly to efficiently manipulate and access the algorithm's output. Unfortunately we were so efficient that they disbanded the team because they didn't have any more work for us",
    },
    {
      clubName: "DISRUPTION LAB",
      clubLink: "https://www.instagram.com/uiuc_disruption/",
      year: "Summer 2024 - Present",
      clubLinkName: "Software Engineer",
      description:
        "I developed a real estate website that facilitates the trading of NFTs for real estate using Solidity on a private blockchain network. By implementing Escrow and User Solidity contracts, I ensured cryptographic security for estate assets and streamlined user orders. Additionally, I designed the front end with React, JavaScript, and Python, and built the back end using Solidity and Besu for the private network",
    },
    {
      clubName: "PROJECT:CODE",
      clubLink: "https://www.instagram.com/project.code.uiuc/",
      year: "Fall 2024, Spring 2024",
      clubLinkName: "Software Engineer",
      description:
        "As a machine learning software engineer in my first semester, I finetuned Whisper to be more accurate on computer science lecture audio. As a club manager in my second semester, I led a team of ten software engineers to construct an interface for the popular Manim mathematical animation library. I taught the engineers to use industry practices like virtual environments, and Docker, and to use the agile methodology",
    },
  ];

  return (
    <>
      <div
        id="clubs"
        className="w-screen h-auto bg-white text-black p-10 pb-5 ">
        <div className="text-5xl text-center font-jetbrains-regular select-none">
          CLUBS & RESEARCH
        </div>
        <div className="pb-10 text-md text-center font-jetbrains-regular select-none">
          and my impact
        </div>
        <div className="grid lg:grid-cols-3 grid-cols-1 gap-8">
          {clubData.map((skill, index) => (
            <ClubCell
              key={index}
              clubName={skill.clubName}
              clubLink={skill.clubLink}
              clubLinkName={skill.clubLinkName}
              description={skill.description}
              year={skill.year}
            />
          ))}
        </div>
      </div>
      <Research />
    </>
  );
}

export default Clubs;
