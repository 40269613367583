import DownArrow from "../assets/downarrow.svg";
import { Link } from "react-scroll";

const HeroSection = ({ boxComponent }) => {
  return (
    <div className="flex w-screen h-screen bg-transparent">
      <div className="lg:w-2/5 lg:block hidden">{boxComponent}</div>

      <div className="lg:w-1/2 mt-24 bg-transparent w-1/2 mx-auto">
        <div className="lg:text-7xl text-5xl font-jetbrains-bolder py-10">
          Music Ratings
        </div>
        <div className="lg:text-3xl text-xl font-jetbrains-regular pb-10">
          Ranking a list of my favorite songs and albums throughout the years.
        </div>
        <div className="hover:cursor-pointer flex text-md font-jetbrains-regular hover:translate-y-1 transition-transform select-none pb-auto">
          <Link to="projects" smooth={true} duration={500}>
            <img className="min-w-1 max-w-24" src={DownArrow} alt=""></img>
            Learn More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
