import "./index.css";
import Github from "../assets/githubBlack";
import LinkedIn from "../assets/linkedinBlack";
import Leetcode from "../assets/leetcode.svg";

function ContactMe({ boxComponent }) {
  return (
    <div id="contactMe" className="my-20 mx-10 w-auto">
      <div className="lg:flex block">
        <div className="align-top lg:p-12">
          <div className="text-left lg:text-7xl text-5xl font-jetbrains-bold pb-5">
            CONTACT ME
          </div>
          <div className="lg:pr-24 lg:text-left w-full pr-12 lg:text-2xl text-lg font-jetbrains-regular pb-5">
            I am currently seeking internships/work experience in software
            engineering. Feel free to email me at anirudl2@illinois.edu.
          </div>
        </div>
        <div className="md:align-center py-auto lg:ml-0 lg:p-12 mx-auto my-auto w-full">
          <div className="grid grid-col-3 gap-3">
            <div className="flex gap-4">
              <a
                draggable="false"
                href="https://www.linkedin.com/in/anirudl/"
                target="_blank"
                rel="noreferrer">
                <LinkedIn />
              </a>
              <a
                draggable="false"
                href="https://github.com/anirudlappathi"
                target="_blank"
                rel="noreferrer">
                <Github />
              </a>
              <a
                draggable="false"
                href="https://leetcode.com/AnirudLappathi/"
                target="_blank"
                rel="noreferrer">
                <img draggable="false" src={Leetcode} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactMe;
