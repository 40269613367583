import "./index.css";
import AWS from "../assets/aws.js";
import CPlusPlus from "../assets/cplusplus.js";
import Flask from "../assets/flask.js";
import Three from "../assets/three.js";
import HTML from "../assets/html.js";
import Java from "../assets/java.js";
import MySQL from "../assets/mysql.js";
import NumPy from "../assets/numpy.js";
import Pandas from "../assets/pandas.js";
import Python from "../assets/python.js";
import React from "../assets/react.js";
import ReactSVG from "../assets/react.js";
import RStudio from "../assets/rstudio.js";
import SQLAlchemy from "../assets/sqlalchemy.js";
import Tailwind from "../assets/tailwind.js";
import OpenCV from "../assets/opencv.js";
import Javascript from "../assets/javascriptSVG.js";

function SkillCell({ skillName, svgImage: SvgComponent }) {
  return (
    <div className="p-4 border-white rounded-md border-solid border-2 h-auto">
      <SvgComponent className="block text-center" />
      <span className="block text-center mt-2 text-white text-lg font-jetbrains-regular select-none">
        {skillName}
      </span>
    </div>
  );
}

function Skills() {
  const skillData = [
    { skillName: "AWS", svgImage: AWS },
    { skillName: "C++", svgImage: CPlusPlus },
    { skillName: "Flask", svgImage: Flask },
    { skillName: "HTML", svgImage: HTML },
    { skillName: "Java", svgImage: Java },
    { skillName: "Javascript", svgImage: Javascript },
    { skillName: "MySQL", svgImage: MySQL },
    { skillName: "NumPy", svgImage: NumPy },
    { skillName: "OpenCV", svgImage: OpenCV },
    { skillName: "Pandas", svgImage: Pandas },
    { skillName: "Python", svgImage: Python },
    { skillName: "React", svgImage: ReactSVG },
    { skillName: "RStudio", svgImage: RStudio },
    { skillName: "SQLAlchemy", svgImage: SQLAlchemy },
    { skillName: "TailwindCSS", svgImage: Tailwind },
    { skillName: "Three.js", svgImage: Three },
  ];

  return (
    <div id="skills" className="h-auto w-screen bg-black text-white p-10">
      <div>
        <div className="pb-7 text-5xl text-center font-jetbrains-regular select-none">
          SKILLS
        </div>
        <div className="grid lg:grid-cols-4 grid-cols-2 gap-8 md:px-20 px-5 h-auto">
          {skillData.map((skill, index) => (
            <SkillCell
              key={index}
              skillName={skill.skillName}
              svgImage={skill.svgImage}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Skills;
