import React, { useState, Suspense } from "react";
import "./index.css";
import Poster from "../assets/KGforSimGNNandKB-1-min.png";

const PosterImage = () => {
  return (
    <>
      <img
        src={Poster}
        alt="Research Poster"
        className="block mx-auto my-4 object-cover"
      />
    </>
  );
};

function Research() {
  const [showPoster, setShowPoster] = useState(false);

  const togglePoster = () => {
    setShowPoster((showPoster) => !showPoster);
  };

  return (
    <div
      id="research"
      className="w-screen h-auto bg-white text-black p-10 pt-5">
      <div className="pb-10">
        <div className="grid lg:grid-cols-1 grid-cols-1 gap-8 p-5 border-black rounded-md border-solid border-2 hover:scale-102 transition-transform">
          <a
            draggable="false"
            href="https://github.com/anirudlappathi/SimGNNwithKnowledgeGraph"
            target="_blank"
            rel="noreferrer"
            className="max-h-1/2 min-w-1/5">
            <span className="block text-black text-center text-3xl font-jetbrains-bolder">
              National Center for Supercomputing Applications (NCSA)
            </span>
            <span className="block  text-black text-center text-sm font-jetbrains-regular underline">
              Source Code (Github)
            </span>
            <span className="block  text-black text-center text-xs font-jetbrains-regular">
              Spring 2024 - Present
            </span>
          </a>
          <button
            onClick={togglePoster}
            className="font-jetbrains-regular block mx-auto p-5 border-black rounded-md border-solid border-2 hover:scale-102 transition-transform max-h-1/2 min-w-1/5">
            {showPoster ? "Hide Poster" : "Show Poster"}
          </button>
          {showPoster && (
            <Suspense fallback={<div>Loading Research Poster...</div>}>
              <PosterImage />
            </Suspense>
          )}
        </div>
      </div>
    </div>
  );
}

export default Research;
