import Header from "../frontpage/Header";
import Gallery from "./Gallery";
import HeroSection from "./Header";

const MusicPage = () => {
  return (
    <>
      <Header linkedLogo={true} />
      <HeroSection />
      <Gallery />
    </>
  );
};

export default MusicPage;
